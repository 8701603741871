import { ProductId } from './businessConstants';

export enum ActiveProductId {
  Direct = 'Direct',
  GoCompare = 'GoCompare',
  CTM = 'Compare The Market',
  MSM = 'Money Super Market',
}

export const activeProductMatchesChosenProducts = (
  chosenProduct: ProductId,
  activeProducts: ActiveProductId[]
): boolean => {
  switch (chosenProduct) {
    case ProductId.DIRECT:
      return activeProducts.includes(ActiveProductId.Direct);
    case ProductId.GO_COMPARE:
      return activeProducts.includes(ActiveProductId.GoCompare);
    case ProductId.COMPARE_THE_MARKET:
      return activeProducts.includes(ActiveProductId.CTM);
    case ProductId.MONEY_SUPERMARKET:
      return activeProducts.includes(ActiveProductId.MSM);
    default:
      return false;
  }
};
