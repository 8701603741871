import Image from '@rsa-digital/evo-shared-components/components/Image';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const DiscountPanelContainer = styled(SimplePanel)<{ children?: React.ReactNode }>`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing(2.25)};

  & h3 {
    margin: 0 0 0;
  }

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
    padding-left: ${spacing(3)};
  `};

  ${mediaQuery.tabletLandscape`
    flex-direction: row;
    padding-left: ${spacing(6)};
  `};
  background-color: ${colors.notificationSuccessLight};
  border-radius: unset;
`;

export const SizedImage = styled(Image)`
  flex-shrink: 0;
  width: ${spacing(6)};
  max-height: ${spacing(6)};
  margin: 0 auto ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-right: ${spacing(3)};
    margin-left: 0;
  `};
`;
