import { PrimaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import DocumentDownloadButton from '@rsa-digital/evo-shared-components/components/DocumentDownloadButton';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const QuoteDetails = styled(RichTextWithModal)`
  margin-bottom: ${spacing(2)};
`;

export const DivWithTopMargin = styled.div`
  margin-top: ${spacing(3)};
`;

export const StyledGridItem = styled(GridItem)`
  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(6)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(3)};
    margin-top: ${spacing(2)};
  `}
`;

export const PolicyWordingDownloadButton = styled(DocumentDownloadButton)`
  height: ${spacing(8)};

  :first-child {
    margin-top: 0;
  }

  ${mediaQuery.tabletPortrait`
    margin-right: ${spacing(4)};
    width: unset;
  `}
`;

export const HomePageButton = styled(PrimaryCta)<{ children?: React.ReactNode }>`
  width: 100%;
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: 0;
    width: unset;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
  `}
`;

export const DividerWithTopMargin = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(8)};
`;
