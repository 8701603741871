import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import { graphql } from 'gatsby';
import React, { Dispatch, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import DiscountPanel from 'components/DiscountPanel';
import HeroBanner from 'components/Header/HeroBanner';
import Layout from 'components/Layout';
import { Link } from 'components/PolicyDocuments';
import RichTextWithModal from 'components/RichTextWithModal';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { useValidConfirmationOffers } from 'helpers/discountHelpers';
import { PageTitle, trackDownloadClick } from 'helpers/eventTracking';
import { handleAndTrackLinkClick } from 'helpers/navigation';
import { usePageTracking } from 'helpers/pageTracking';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import {
  CsAsset,
  CsCoverLevelOfferInformation,
  CsHero,
  CsIcon,
} from 'types/contentStack';
import {
  ButtonContainer,
  DividerWithTopMargin,
  DivWithTopMargin,
  HomePageButton,
  PolicyWordingDownloadButton,
  QuoteDetails,
  StyledGridItem,
} from './styles';

type ConfirmationProps = {
  data: {
    csPetConfirmation: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
      next_button_url: string;
      quote_details: string;
      documents_dispatching_details: string;
      policy_wording_link: Link;
      bullet_icon: [CsIcon] | [];
      image: CsAsset;
    };
  };
};

export const query = graphql`
  query {
    csPetConfirmation {
      meta_title
      hero {
        heading
        subheading
      }
      next_button_text
      next_button_url
      quote_details
      documents_dispatching_details
      policy_wording_link {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      image {
        ...CsAsset
      }
    }
  }
`;

const Confirmation: React.FC<ConfirmationProps> = ({
  data: {
    csPetConfirmation: {
      hero,
      next_button_text,
      next_button_url,
      meta_title,
      quote_details,
      documents_dispatching_details,
      policy_wording_link,
      image,
    },
  },
}) => {
  const [quote] = useConfirmationQuote();

  const catBreedsRefData = useReferenceData('catBreeds');
  const dogBreedsRefData = useReferenceData('dogBreeds');
  const referenceDataLoaded = !!catBreedsRefData && !!dogBreedsRefData;

  usePageTracking(meta_title, !!quote && referenceDataLoaded, quote);

  const dispatchError: Dispatch<ErrorAction> = useDispatch();
  const [errorDispatched, setErrorDispatched] = useReducer(() => true, false);

  const processedImage = processImageAsset(image);

  const offers = useValidConfirmationOffers();

  if (!quote) {
    if (!errorDispatched) {
      dispatchError({
        type: ERROR,
        errorType: ErrorType.CONFIRMATION_SESSION_EXPIRED,
      });
      setErrorDispatched();
    }

    return null;
  }

  const replaceQuotePlaceholders = replacePlaceholdersRichText(quotePlaceholders, quote);

  return (
    <Layout metaTitle={meta_title} pageTitle={PageTitle.Confirmation}>
      <HeroBanner heading={hero.heading} subheading={hero.subheading} />
      <Grid alignLeft>
        <StyledGridItem desktop={7} tabletLandscape={7}>
          <QuoteDetails
            html={replaceQuotePlaceholders(quote_details)}
            pageTitle={PageTitle.Confirmation}
          />
          <RichTextWithModal
            html={documents_dispatching_details}
            pageTitle={PageTitle.Confirmation}
          />
          <DivWithTopMargin>
            {offers &&
              offers.map((offer: CsCoverLevelOfferInformation) => (
                <DiscountPanel offerPanel={offer} pageTitle={PageTitle.QuoteSummary} />
              ))}
          </DivWithTopMargin>
          <ButtonContainer>
            <PolicyWordingDownloadButton
              documentName={policy_wording_link.text}
              documentUrl={policy_wording_link.url}
              onClick={() =>
                trackDownloadClick(policy_wording_link.text, policy_wording_link.url)
              }
              openInNewTab={policy_wording_link.open_in_new_tab}
            />
            <HomePageButton
              cta={{
                url: next_button_url,
                displayText: next_button_text,
                onClick: (e) => {
                  handleAndTrackLinkClick(
                    next_button_url,
                    PageTitle.Confirmation,
                    'Go to homepage',
                    e
                  );
                },
              }}>
              {next_button_text}
            </HomePageButton>
          </ButtonContainer>
        </StyledGridItem>
        <GridItem desktop={5} tabletLandscape={5} tabletPortrait={5}>
          {processedImage && <Image image={processedImage} />}
        </GridItem>
      </Grid>
      <DividerWithTopMargin />
    </Layout>
  );
};

export default Confirmation;
