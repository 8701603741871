import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { BackgroundContainer, Hero, Subheading } from './styles';

type HeroBannerProps = {
  heading?: string;
  subheading?: string;
  subheadingIsPii?: boolean;
} & ComponentProps;

const HeroBanner: React.FC<HeroBannerProps> = ({
  heading,
  subheading,
  subheadingIsPii,
  ...props
}) => (
  <BackgroundContainer {...componentProps(props)}>
    {heading && (
      <Hero>
        <div dangerouslySetInnerHTML={{ __html: heading }} />
      </Hero>
    )}
    {subheading && (
      <Grid alignLeft>
        <GridItem desktop={8} tabletLandscape={8} tabletPortrait={6}>
          <Subheading data-pii-mask={subheadingIsPii}>{subheading}</Subheading>
        </GridItem>
      </Grid>
    )}
  </BackgroundContainer>
);
export default HeroBanner;
