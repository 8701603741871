import { getStartOfDay } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import {
  appliesToSelection,
  getProductFromQuoteOptions,
  Product,
} from 'helpers/productHelpers';
import { useReferralCodes } from 'state/formData/referralCodes';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import {
  CsApplicableProducts,
  CsAsset,
  CsCoverLevelOfferInformation,
} from 'types/contentStack';
import { activeProductMatchesChosenProducts } from './activeProductHelpers';
import { processImageAsset } from './csTypeProcessors';
import { CurrentQuote, useCurrentQuote } from './useCurrentQuote';

type CsReferralCodesDiscount = {
  affinity_code: string;
  subaffinity_code: string;
  active_cover_levels: CsApplicableProducts;
  referral_codes_discount_rich_text: string;
  referral_codes_discount_icon: CsAsset | null;
};

export const displayMultipetDiscount = (quote: CurrentQuote): boolean => {
  return (quote.petInfos?.length || 0) > 1;
};

// Tesco do not want this for now, but may in future - current agreed upon approach is to have it show if an icon is found in CS, see EP-514 for details
// We are keeping the same approach for MT, so this can be switched off by removing the icon in CS
export const displayOnlineDiscount = (onlineDiscountIcon: CsAsset | null): boolean => {
  const onlineDiscountLogo = processImageAsset(onlineDiscountIcon);
  return onlineDiscountLogo !== undefined;
};

type CsPetOfferPanel = {
  csPetOffers: {
    quote_summary_offers: CsCoverLevelOfferInformation[];
    price_panel_offers: CsCoverLevelOfferInformation[];
    quote_confirmation_offers: CsCoverLevelOfferInformation[];
  };
  csPetQuoteSummaryOfYourNeeds: {
    discounts: {
      referral_codes_discounts: CsReferralCodesDiscount[];
      online_discount_icon: CsAsset | null;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      discounts {
        online_discount_icon {
          ...CsAsset
        }
        referral_codes_discounts {
          affinity_code
          subaffinity_code
          active_cover_levels {
            product_selector
          }
          referral_codes_discount_rich_text
          referral_codes_discount_icon {
            ...CsAsset
          }
        }
      }
    }
    csPetOffers {
      quote_summary_offers {
        icon {
          ...CsAsset
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        active_pet_type
        offer_start_date
        offer_end_date
      }
      price_panel_offers {
        icon {
          ...CsAsset
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        active_pet_type
        offer_start_date
        offer_end_date
      }
      quote_confirmation_offers {
        icon {
          ...CsAsset
        }
        content_rich_text
        active_routes_to_quote
        active_cover_levels
        active_pet_type
        offer_start_date
        offer_end_date
      }
    }
  }
`;

const useQuery = (): CsPetOfferPanel => useStaticQuery<CsPetOfferPanel>(query);

export const isCoverLevelActive = (
  productType: Product,
  activeCoverLevels: Product[]
): boolean => {
  switch (productType) {
    case Product.Basic:
      return activeCoverLevels.includes(Product.Basic);
    case Product.Classic:
      return activeCoverLevels.includes(Product.Classic);
    case Product.Premier:
      return activeCoverLevels.includes(Product.Premier);
    default:
      return false;
  }
};

interface PetInfo {
  petType: string;
}

export const isActivePetType = (
  activeQuote: CurrentQuote,
  activePetType: string[]
): boolean => {
  const petTypes: string[] = [
    ...new Set(activeQuote?.petInfos?.map((petInfo: PetInfo) => petInfo.petType)),
  ];
  const offerPetTypes = activePetType.map((petType) => {
    switch (petType) {
      case 'Cat':
        return 'NWA_PET_C';
      case 'Dog':
        return 'NWA_PET_D';
      default:
        return '';
    }
  });

  return offerPetTypes.some((item) => petTypes.includes(item));
};

export const offerIsInActiveRange = (
  offerStartDate: string | undefined,
  offerEndDate: string | undefined
): boolean => {
  const startOfToday = getStartOfDay(new Date());

  const offerStarted = !offerStartDate || startOfToday >= new Date(offerStartDate);
  const offerNotExpired = !offerEndDate || startOfToday <= new Date(offerEndDate);
  return offerStarted && offerNotExpired;
};

// Uses the current quote to find the relevant offer panel if any are applicable
export const useValidQuoteSummaryOffers = ():
  | CsCoverLevelOfferInformation[]
  | undefined => {
  const {
    csPetOffers: { quote_summary_offers },
  } = useQuery();
  const quote = useCurrentQuote();
  const offers = quote_summary_offers.filter(
    ({
      active_routes_to_quote,
      active_cover_levels,
      active_pet_type,
      offer_start_date,
      offer_end_date,
    }) =>
      activeProductMatchesChosenProducts(quote.productId, active_routes_to_quote) &&
      isCoverLevelActive(
        getProductFromQuoteOptions(quote.quoteOptions) as Product,
        active_cover_levels
      ) &&
      offerIsInActiveRange(offer_start_date, offer_end_date) &&
      isActivePetType(quote, active_pet_type)
  );

  if (offers) {
    return offers;
  }

  return undefined;
};

export const useValidPricePanelOffers = ():
  | CsCoverLevelOfferInformation[]
  | undefined => {
  const {
    csPetOffers: { price_panel_offers },
  } = useQuery();
  const quote = useCurrentQuote();

  const offers = price_panel_offers.filter(
    ({
      active_routes_to_quote,
      active_cover_levels,
      active_pet_type,
      offer_start_date,
      offer_end_date,
    }) =>
      activeProductMatchesChosenProducts(quote.productId, active_routes_to_quote) &&
      isCoverLevelActive(
        getProductFromQuoteOptions(quote.quoteOptions) as Product,
        active_cover_levels
      ) &&
      offerIsInActiveRange(offer_start_date, offer_end_date) &&
      isActivePetType(quote, active_pet_type)
  );

  if (offers) {
    return offers;
  }

  return undefined;
};

export const useValidConfirmationOffers = ():
  | CsCoverLevelOfferInformation[]
  | undefined => {
  const {
    csPetOffers: { quote_confirmation_offers },
  } = useQuery();
  const [quote] = useConfirmationQuote();

  if (quote) {
    const offers = quote_confirmation_offers.filter(
      ({
        active_routes_to_quote,
        active_cover_levels,
        active_pet_type,
        offer_start_date,
        offer_end_date,
      }) =>
        activeProductMatchesChosenProducts(quote.productId, active_routes_to_quote) &&
        isCoverLevelActive(
          getProductFromQuoteOptions(quote.quoteOptions) as Product,
          active_cover_levels
        ) &&
        offerIsInActiveRange(offer_start_date, offer_end_date) &&
        isActivePetType(quote, active_pet_type)
    );

    if (offers) {
      return offers;
    }
  }

  return undefined;
};

export const useGetDiscountContent = (): [
  CsReferralCodesDiscount | undefined,
  boolean
] => {
  const {
    csPetQuoteSummaryOfYourNeeds: {
      discounts: { referral_codes_discounts, online_discount_icon },
    },
  } = useQuery();
  const [referralCodes] = useReferralCodes();
  const quote = useCurrentQuote();

  const validReferralCodesDiscount = referral_codes_discounts.find(
    (referral_codes_discount) =>
      referral_codes_discount.affinity_code === referralCodes.affinity &&
      referral_codes_discount.subaffinity_code === referralCodes.subAffinity &&
      appliesToSelection(quote.quoteOptions, referral_codes_discount.active_cover_levels)
  );

  const pricePanelOffers = useValidPricePanelOffers();
  const displayPricePanelOffers = pricePanelOffers ? pricePanelOffers.length > 0 : false;

  const displayQuoteSummaryDiscounts =
    displayMultipetDiscount(quote) ||
    displayOnlineDiscount(online_discount_icon) ||
    displayPricePanelOffers ||
    !!validReferralCodesDiscount;

  return [validReferralCodesDiscount, displayQuoteSummaryDiscounts];
};
